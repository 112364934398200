<template>
  <Head>
    <Link rel="prev" :href="`https://${shopZone.domain}${getLink(props.currentPage - 1)}`" v-if="hasPrev" />
    <Link rel="next" :href="`https://${shopZone.domain}${getLink(props.currentPage + 1)}`" v-if="hasNext" />
  </Head>
  <div class="paging" :class="props.class">
    <div v-if="props.totalPages > 1">
      
        <div class="paging__pages">
          <AtomsGlobalLink :to="getLink(props.currentPage - 1)" v-if="hasPrev" class="paging__page" @click="pageChanged">
            <AtomsImagesIcon icon="arrow-left-carousel" size="xs" />
          </AtomsGlobalLink>
          <AtomsGlobalLink :to="getLink(page.number)" v-for="page in pages" class="paging__page" :class="{ 'paging__page--current': page.isCurrent }" @click="pageChanged">{{ page.number }}</AtomsGlobalLink>
          <AtomsGlobalLink :to="getLink(props.currentPage + 1)" v-if="hasNext" class="paging__page" @click="pageChanged">
            <AtomsImagesIcon icon="arrow-right-carousel" size="xs" />
          </AtomsGlobalLink>
        </div>
      
    </div>
    <div v-if="!props.appendDisabled" class="paging__append">
      <div class="paging__append__wrapper">
        <MoleculesButtonsButton :arrow-right="true" @click="append" v-if="hasNext">{{ props.labelMore ?? $t('Načíst další') }}</MoleculesButtonsButton>
      </div>
    </div>
  </div>
</template>
<script setup>

const props = defineProps({
  currentPage: Number,
  totalPages: Number,
  shownItems: Number,
  totalItems: Number,
  class: String,
  label: String,
  labelMore: String,
  autoScrollElement: Object | undefined,
  appendDisabled: Boolean | undefined
});

const hasPrev = computed(() => props.currentPage > 1);
const hasNext = computed(() => props.currentPage < props.totalPages);

const config = useRuntimeConfig();
const shopRouting = useShopRouting();
const shopZone = useShopZone().value;

const getLink = pageNumber => {

  const pathAndQuery = useRoute().fullPath.split('#')[0];
  
  const newHash = shopRouting.stringifyHash(shopRouting.updateValueInHash('p', pageNumber, false, true));

  return pathAndQuery + newHash;
};

const pages = computed(() => {
  let pages = [];

  let minNumber = Math.max(props.currentPage - 2, 1);
  let maxNumber = Math.min(props.currentPage + 2, props.totalPages);

  if (props.currentPage === 1 && props.totalPages > 3) {
    maxNumber++;
  }

  if (props.currentPage === props.totalPages && minNumber > 1) {
    minNumber--;
  }

  for (let i = minNumber; i <= maxNumber; i++) {
    pages.push({
      number: i,
      isCurrent: i === props.currentPage
    });
  }

  return pages;

});

const pageChanged = () => {

  if (props.autoScrollElement) {
    useUtils().scrollToElement(props.autoScrollElement);
  }
}

const append = () => {
  useRouter().push(getLink((props.currentPage + 1) + '-a'));
}

</script>
<style lang="postcss">
.paging {

  @apply text-center space-y-6;

  &__pages {
    @apply flex justify-center gap-2 tablet:justify-end;
  }

  &__page {

    @apply flex items-center justify-center w-10 aspect-square text-xl bg-neutral-100 transition-colors;

    &:hover,
    &--current {
      @apply no-underline bg-primary-500 text-neutral-50;
    }
  }

  &__append {
    @apply relative flex items-center justify-center;

    &:before {
      @apply content-[''] absolute left-0 right-0 border-b border-neutral-200;
    }

    &__wrapper {
      @apply flex-1 flex relative z-[1] bg-neutral-50 tablet:flex-none tablet:px-8;

      .button {
        @apply flex-1 desktop:flex-none;
      }
    }
  }
}
</style>